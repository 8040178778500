<template>
  <div>
    <vs-row class="flex justify-between">
      <div class="sm:w-1/2">
        <div class="flex flex-wrap items-center justify-between wrap-span">
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeName">{{ paymentRequestDetail.payeeName }}</h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeEmail && !paymentRequestDetail.payeeName">
            <a :href="`mailto:${paymentRequestDetail.payeeEmail}`">{{ paymentRequestDetail.payeeEmail }}</a>
          </h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail">
            {{ paymentRequestDetail.payeePhone }}
          </h3>
          <h3 class="font-normal mb-2" v-if="!paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail && productConfigType == 'THREE_PARTY'">
            Customer details not available
          </h3>
        </div>

        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Request ID:</p>
          <a class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base" v-if="['Pending'].includes(PRDpaymentStatus)" @click="gotoPaymenPage">{{ paymentRequestDetail.paymentRequestId }}</a>
          <p v-else class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.paymentRequestId }}</p>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Reference:</p>
          <p class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.reference }}</p>
        </div>
        <div v-if="paymentRequestDetail.partnerDetails && paymentRequestDetail.partnerDetails.merchantId" class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Merchant:</p>
          <p class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base"><a href="#" @click="gotoMerchant()">{{ paymentRequestDetail.partnerDetails.merchantId }}</a> ({{ paymentRequestDetail.partnerDetails.companyName }})</p>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Created by:</p>
          <p class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.createdBy }}</p>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <!-- for recurring type of payments -->
          <p class="w-24 m-0 text-base" v-if="isRecurring">Recurring</p>
          <!-- for recurring, active state, clickable, popups billing stuff-->
          <span v-else-if="isRecurring && PRDpaymentStatus === 'Active'" class="lg:w-1/2 md:w-auto sm:w-auto mb-0 ml-4 text-base">
            <u class="link">billed {{ paymentRequestDetail.frequency.interval }} times every {{ paymentRequestDetail.frequency.every }}</u>
          </span>
          <!-- plans pop up -->
          <payment-plans-pop-up v-if="payLaterPopUp" title="Payment schedule" :isOpen.sync="payLaterPopUp" :calculations="calculations" :paymentRequest="paymentRequestDetail" :onClose="closePayLaterPopUp"/>
        </div>
        <div class="mt-4 flex">
          <p><span :class="`payment-status mr-5 font-normal inline-block ${conditionalClass(PRDpaymentStatus)}`">{{ PRDpaymentStatus }}</span></p>
          <span class="text-base block md:inline-block pt-2 md:pt-0">
            {{ ["Pending"].includes(PRDpaymentStatus) ? `(expires ${momentExpiryDate(paymentRequestDetail.expiredDate)})` : "" }}
          </span>
          <span v-if="PRDpaymentStatus == 'Declined'" class="text-danger font-bold">Decline reason:</span>
          <p class="ml-4" v-if="PRDpaymentStatus == 'Declined'">
            <span :key="index" v-for="(reason, index) in paymentDeclineReason" class="text-danger">
              {{ getDeclineText(reason) }}<br />
            </span>
          </p>
        </div>

        <br />
        <br />
        <div class="button-combo">
          <vs-button flat @click="approvePR" v-round size="medium" class="mr-3" v-if="PRDpaymentStatus === 'Submitted' && ['admin', 'superAdmin'].includes(user.userType)">
            <span>Approve</span>
          </vs-button>
          <vs-button flat @click="showDeclinePopUp" v-round size="medium" class="mr-3 only-border-btn" v-if="PRDpaymentStatus === 'Submitted' && ['admin', 'superAdmin'].includes(user.userType)">
            <span>Decline</span>
          </vs-button>
          <vs-button class="mr-3" flat @click="openChangeDueDatePopup" v-round size="medium" v-if="hasPaylater && selectedPlan && selectedPlan.currentPlan && selectedPlan.currentPlan.productType != 'B2C_BNPL' && (PRDpaymentStatus === 'Active' || PRDpaymentStatus === 'Extended')">
            Change due date
          </vs-button>
          <vs-button flat @click="openRegisterBankTransferPopup" v-round size="medium" v-if="hasPaylater && (PRDpaymentStatus === 'Active' || PRDpaymentStatus === 'Extended')">
            Register bank transfer
          </vs-button>
          <vs-button
            flat
            @click="openDeletePopUp"
            v-round
            size="medium"
            class="only-border-btn"
            v-if="['Pending', 'Info-required', 'Submitted'].includes(PRDpaymentStatus) ||
              (PRDpaymentStatus === 'Expired' && isOneOff && PRDrequestOptions.includes('pay-now')) ||
              (PRDpaymentStatus === 'Active' && isRecurring)"
            :disabled="PRDpaymentStatus === 'Expired'">
            Cancel Request
          </vs-button>

          <!-- button displays only when payment status is expired or Pending -->
          <vs-button flat @click="openResendPopUp" v-round size="medium" class="ml-3" v-if="['Expired', 'Pending'].includes(PRDpaymentStatus) && (paymentRequestDetail.payeeEmail || paymentRequestDetail.payeePhone)">
            <span>Resend Request</span>
          </vs-button>

          <vs-button flat @click="openStatusPopUp" v-round size="medium" class="ml-3" v-if="['Pending'].includes(PRDpaymentStatus) && productConfigType == 'THREE_PARTY'">
            <span>Mark as paid</span>
          </vs-button>
        </div>
      </div>

      <!-- right side -->
      <div class="sm:w-1/2 flex grid flex-col">
        <div class="lg:w-4/5 w-full justify-self-end">
          <div class="flex flex-col wrap-span" v-if="isOneOff && (hasPayNow || hasPaylater)">
            <div class="flex flex-wrap items-center justify-between wrap-span mb-2 lg:mt-0 mt-10">
              <h3 class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Request amount</h3>
              <h3 class="font-normal mid-blue">
                {{ paymentRequestDetail.paymentSummary.requestAmount }}
              </h3>
            </div>

            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="paymentRequestDetail.paymentSummary && paymentRequestDetail.paymentSummary.topUp">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Top-ups</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentSummary.topUp }}
              </h3>
            </div>

            <div class="flex justify-between mb-2"
              v-if="isOneOff && hasPayNow &&
                PRDpaymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              ">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentSummary.fees }}
              </h3>
            </div>

            <div class="flex justify-between mb-2"
              v-if="isOneOff && hasPayNow &&
                PRDpaymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Amount payable by customer</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template v-if="paymentRequestDetail.payNowUsedCard">{{ paymentRequestDetail.paymentSummary.payableByCustomer }}</template>
              </h3>
            </div>

            <div class="flex justify-between mb-2"
              v-if="isOneOff && hasPayNow &&
                PRDpaymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Payable to you</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template>
                  {{ paymentRequestDetail.paymentSummary.payableToYou }}
                </template>
              </h3>
            </div>
          </div>

          <div class="flex flex-col wrap-span" v-else-if="isRecurring">
            <div class="font-normal text-black text-2xl">Payment Detail</div>
            <div class="flex">
              <p class="mb-0 lg:w-2/3 md:w-auto w-max lg:text-lg md:text-base text-sm">Recurring amount</p>
              <h3 class="font-normal lg:w-1/3 md:w-auto w:max text-right mid-blue">{{ moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}</h3>
            </div>
            <div class="flex mb-2" v-if="PRDpaymentStatus === 'Active'">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ PRDpaymentStatus === "Active" ? moneyFormat(PRDtransactionSurcharge) : 0 }}
              </h3>
            </div>

            <div class="flex" v-if="PRDpaymentStatus === 'Active'">
              <p class="lg:w-3/4 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Total recurring amount</p>
              <h3 class="lg:w-1/4 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ PRDpaymentStatus === "Active" ? moneyFormat(paymentRequestDetail.transactions[0].totalAmount) : moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}
              </h3>
            </div>
          </div>
          <!-- Pay later -->
          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv()">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey" v-if="selectedPlan">
              {{ this.selectedPlan.feeModel == 'MSF_ADDED' ? "Service fee (paid by customer)" : "Service fee (paid by merchant)" }}
            </p>
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey" v-else-if="selectedPlanCancelled">
              {{ this.selectedPlanCancelled.feeModel == 'MSF_ADDED' ? "Service fee (paid by customer)" : "Service fee (paid by merchant)" }}
            </p>
            <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.fees }}
            </span>
          </div>

          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv()">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Total payable by customer</p>
            <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.payableByCustomer }}
            </span>
          </div>

          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv()">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Payable to you</p>
            <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.payableToYou }}
            </span>
          </div>

          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && !['Cancelled', 'Declined', 'Expired','Pending', 'Submitted'].includes(PRDpaymentStatus) && showCurrentBalance()">
            <p class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Current balance</p>
            <h3 class="font-normal mid-blue">{{ moneyFormat(paymentRequestDetail.balances && paymentRequestDetail.balances.balance ? paymentRequestDetail.balances.balance : 0) }}</h3>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row class="flex justify-center pointer pt-5">
      <div @click="toggleShowMore" class="see-more flex flex-col">
        <p class="text-base">See more</p>
        <vs-icon :icon="showMore ? `expand_less` : `expand_more`" size="small"></vs-icon>
      </div>
    </vs-row>

    <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" title="Resend Request" :active.sync="resendPopUp">
      <p>Are you sure you want to resend this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="resend" class="mr-5" :disabled="resendButtonDisable">{{ resendButtonText }}</vs-button>
        <vs-button @click="hideResendPopup" :disabled="resendButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- pop up after move to complete is clicked -->
    <vs-popup title="Mark as paid (payment received via other methods)" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="statusPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">This action will change the payment request status to complete, and create a transaction for display purposes only indicating that funds have been disbursed to the nominated merchant account - are you sure you want to proceed?</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="updateStatus" action-btn :disabled="statusButtonDisable">Continue</vs-button>
        <vs-button color="danger" @click="hideStatusPopup" size="large" action-btn class="ml-6 md:ml-10" :disabled="statusButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- due date popup code starts here -->
    <vs-popup v-if="(PRDpaymentStatus === 'Active' || PRDpaymentStatus === 'Extended')" class="holamundo changeDueDate"  title="Change due date" :active.sync="changeDueDate">
      <changeDueDate
        @clicked="reloadContent"
        :paymentRequestId="paymentRequestDetail._id"
        :selectedPaymentPlan="paymentRequestDetail.selectedPaymentPlan"
        :paymentRequestCustomId="paymentRequestDetail.paymentRequestId"
        :calculations="calculations"
        :openingDate="paymentRequestDetail.openingDate"
        @closePopUp="changeDueDate = false"
      />
    </vs-popup>

    <!-- register bank transfer popup code starts here -->
    <vs-popup class="holamundo registerPopop" :active.sync="registerBankTransferPopup" title="Register bank transfer" @close="closeRegisterBankTransferModal" id="popupWrapper">
      <vs-row id="widgetBanner" class="pb-1">
        <vs-col vs-w="10" class="mx-auto">
          <vs-row>
            <vs-col vs-w="6" vs-xs="12" class="my-4 md:my-6 px-3 text-center">
              <div class="text-base font-light mb-2">Current plan balance</div>
              <div class="text-lg font-normal md:text-xl dark-blue leading-tight">$ {{ commaSeparator(paymentRequestDetail.balances && paymentRequestDetail.balances.balance ? paymentRequestDetail.balances.balance : 0) }}</div>
            </vs-col>
            <vs-col vs-w="6" vs-xs="12" style="text-align:center;" class="my-4 md:my-6 px-3">
              <div class="text-base font-light mb-2">Plan balance after bank transfer</div>
              <div class="text-lg font-normal md:text-xl dark-blue leading-tight" v-if="balanceAfterTransferBool">{{ balanceAfterTransfer }}</div>
              <div class="text-lg font-normal md:text-xl red-text leading-tight" v-else>{{ balanceAfterTransfer }}</div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <div class="registerPopop--content">
        <p class="block text-sm mb-8 text-grey">Please enter the below details about the payment made by bank transfer.</p>
        <div class="w-full">
          <div class="flex flex-col lg:flex-row w-full">
            <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="amount" class="w-full text-base font-light text-dark-blue block pb-1">Payment amount <span class="mid-blue">*</span></label>
              <div class="flex items-center relative">
                <span class="pr-1 absolute l-0 t-0 text-dark-blue">$</span>
                <money class="moneyPR w-full pl-5 font-light" v-model="register.amount" name="amount" :precision="2" :placeholder="'Amount'" id="amount" v-validate="rules"></money>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first("amount") }}</span>
            </div>
            <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="transactionReference" class="w-full text-base font-light text-dark-blue block pb-1">
                Transaction reference<span class="mid-blue">*</span>
              </label>
              <vs-input
                v-model="register.transactionReference"
                data-vv-validate-on="blur"
                data-vv-as="transaction reference"
                v-validate="'required|max:20'"
                name="transactionReference"
                id="transactionReference"
                class="w-full font-light"
              />
              <span class="text-danger text-sm pt-2 block" v-show="errors.has('transactionReference')" >{{ errors.first("transactionReference") }}</span>
            </div>
          </div>
          <div class="flex flex-col md:flex-row w-full">
            <div class="mb-6 md:mb-8 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="paymentDate" class="w-full text-base font-light text-dark-blue block pb-1">Payment date <span class="mid-blue">*</span></label>
              <date-picker
                v-validate="'required'"
                data-vv-validate-on="blur"
                data-vv-as="payment date"
                name="paymentDate"
                id="paymentDate"
                v-model="register.paymentDate"
                lang="en"
                type="date"
                placeholder="DD/MM/YYYY"
                class="w-full font-light"
                format="DD/MM/YYYY"
                style="order: 2; max-width:330px"
                :disabled-days="disabledDate"
              ></date-picker>
              <span class="text-danger text-sm pt-2 block" v-if="errors.has('paymentDate')">{{ errors.first("paymentDate") }}</span>
            </div>
          </div>
          <div class="flex flex-col md:flex-row w-full">
            <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="auditNote" class="w-full text-base font-light text-dark-blue block pb-1">Audit note </label>
              <vs-textarea
                v-model="register.auditNote"
                counter="250"
                maxlength="250"
                :counter-danger.sync="counterDanger"
                rows="2"
                id="auditNote"
                name="auditNote"
                class="w-full"
                style="max-width:330px;"/>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-4 md:mt-6 lg:mt-8 pt-1">
          <vs-button class="mr-8" v-round size="large" @click="registerBankTransfer">Register</vs-button>
          <a class="text-xs flex items-center underline mid-blue" @click="closeRegisterBankTransferModal">Cancel</a>
        </div>
      </div>
    </vs-popup>

     <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" title="Cancel Request" :active.sync="deletePopUp">
      <p>Are you sure you want to cancel this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="cancel" class="mr-5">Confirm</vs-button>
        <vs-button @click="hideDeletePopup">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- pop up after decline request is clicked -->
    <vs-popup title="Select a decline reason" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="declinePopUp">
      <div class="w-2/3">
        <label class="flex mb-2">Please confirm the reason/s for declining this request</label>
        <multiselect
          :multiple="true"
          v-model="declineReason"
          :options="paymentRequestDeclineReason"
          :selectLabel="''"
          :deselectLabel="''"
          :close-on-select="false"
          :preselectFirst="false"
          :selectedLabel="''"
          label="reason"
          track-by="reason"
          class="w-2/3"
          @open="handleDeclinePopup('open')"
          @close="handleDeclinePopup('close')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('declineReason')">{{ errors.first("declineReason") }}</span>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn @click="declinePR" :disabled="declineReason.length == 0">Save</vs-button>
        <vs-button @click="hideDeclinePopUp" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import PaymentPlansPopUp from "./PaymentPlansPopUp.vue";
import moment from "moment";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import changeDueDate from "./changeDueDate.vue";
import { mapActions } from "vuex";
import VeeValidate from "vee-validate";
import { sentryLog } from '../../../../../helper/sentryLog';
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

export default {
  components: {
    changeDueDate,
    DatePicker,
    Money,
    Multiselect,
    PaymentPlansPopUp,
  },

  props: {
    paymentRequestDetail: { type: Object },
    toggleShowMore: { type: Function },
    cancelPaymentRequest: { type: Function },
    calculations: { type: Object },
    resendPaymentRequest: { type: Function },
    showMore: { type: Boolean },
    productConfigType: { type: String },
    updatePaymentRequest: { type: Function },
  },

  data() {
    return {
      payLaterPopUp: false,
      deletePopUp: false,
      msf: 0,
      totalAmount: 0,
      resendPopUp: false,
      statusPopUp: false,
      registerBankTransferPopup: false,
      amPm: "",
      counterDanger: "",
      register: {
        amount: "",
        paymentDate: "",
        paymentTime: "",
        transactionReference: "",
        auditNote: "",
      },
      balanceAfterTransferBool: false,
      PRDpaymentStatus : "",
      PRDrequestType : "",
      PRDrequestOptions : "",
      user: "",
      resendButtonDisable: false,
      resendButtonText: "Confirm",
      statusButtonDisable: false,
      changeDueDate: false,
      declineButtonDisable: false,
      declinePopUp: false,
      declineReason: [],
      paymentRequestDeclineReason: [],
      paymentRequestDeclineDefaultReason: [],
      allReasonSelected: false,
      paymentDeclineReason: [],
    };
  },

  methods: {
    ...mapActions("paymentRequest", ["registerBankTransferPost", "approvePaymentRequest", "declinePaymentRequest"]),
    ...mapActions("setting", ["fetchSetting"]),

    gotoMerchant() {
      let routeData = `${process.env.VUE_APP_BASE_URL}partners/${this.paymentRequestDetail.partnerId}/edit`;
      window.open(routeData, "_blank");
    },

    gotoPaymenPage() {
      let routeData = `${process.env.VUE_APP_CLIENT_API}payments/${this.paymentRequestDetail._id}`;
      window.open(routeData, "_blank");
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },

    disabledDate(date) {
      let datehere = new Date(this.paymentRequestDetail.openingDate);
      datehere.setDate(datehere.getDate() - 1);
      return date < datehere || date > new Date();
    },

    reloadContent(data) {
      if (data && data.modalClose) {
        this.changeDueDate = false;
      }
      this.$emit("updated", { reload: true });
    },

    openChangeDueDatePopup() {
      this.changeDueDate = true;
      this.errors.clear();
    },
    openRegisterBankTransferPopup() {
      this.registerBankTransferPopup = true;
      this.register = {};
      this.amPm = "";
      this.errors.clear();
    },

    closeRegisterBankTransferModal() {
      this.registerBankTransferPopup = false;
      this.register = {};
      this.amPm = "";
      this.errors.clear();
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon
      });
    },

    async registerBankTransfer() {
      const valid = await this.$validator.validate();

      if (valid) {
        this.$vs.loading();
        const id = this.$route.params.id;
        // paymentDate is converted to string here to avoid error in date format for different timezones
        this.register.paymentDate = moment(this.register.paymentDate).format("YYYY-MM-DD");
        this.registerBankTransferPost({ data: this.register, id: id }).then((res) => {
          this.$vs.loading.close();
          this.closeRegisterBankTransferModal();
          this.showMessage("Success", "The bank transfer has been successfully registered.", "success", "icon-check-circle");
          this.$emit("update-transactions");
          this.register = {};
          this.amPm = "";
          this.errors.clear();
        }).catch((ex) => {
          this.errors.clear();

          if (ex.data.data.showInline) {
            this.errors.add({
              field: "paymentDate",
              msg: ex.data.message
            });
          }

          this.$vs.loading.close();
          sentryLog(ex);
          this.showErrorMessage("Error", ex.data.message);
        });
      }
    },
    openDeletePopUp() {
      this.deletePopUp = true;
    },
    openResendPopUp() {
      this.resendPopUp = true;
    },
    openStatusPopUp() {
      this.statusPopUp = true;
    },
    cancel() {
      this.cancelPaymentRequest();
      this.$router.push({ name: "payment-activity" });
    },
    hideDeletePopup() {
      this.deletePopUp = false;
    },
    hideResendPopup() {
      this.resendPopUp = false;
    },
    hideStatusPopup() {
      this.statusPopUp = false;
    },
    resend() {
      this.resendButtonDisable = true;
      this.resendButtonText = 'Processing';
      this.resendPaymentRequest();
    },
    showPlans() {
      this.payLaterPopUp = true;
    },
    conditionalClass(value = "Pending") {
      let colorClass;
      switch (value) {
        case "Pending":
          colorClass = "yellow";
          break;
        case "Info-required":
          colorClass = "yellow";
          break;
        case "Expired":
          colorClass = "red";
          break;
        case "Completed":
          colorClass = "green";
          break;
        case "Cancelled":
        case "Declined":
          colorClass = "red";
          break;
        case "Active":
          colorClass = "blue";
          break;
        case "Refunded":
          colorClass = "yellow";
          break;
        case "Extended":
          colorClass = "blue";
          break;
        case "Submitted":
          colorClass = "yellow";
          break;
      }
      return colorClass;
    },
    momentExpiryDate(date) {
      return moment(date).format("DD-MMM-YYYY HH:mm");
    },
    closePayLaterPopUp() {
      this.payLaterPopUp = false;
    },
    commaSeparator (num) {
      let numString = String(num);
      let splitted = numString.split(".");
      let oldNumber = splitted[0];
      let decimalPoint = splitted[1] ? splitted[1].substring(0,2) : "00";
      decimalPoint = decimalPoint.length == 1 ? `${decimalPoint}0` : decimalPoint;
      let newNumber = "";
      let stringLength = oldNumber.length - 1;
      let counter = 0;
      let isNegative = parseInt(oldNumber) < 0 ? "-" : "";

      if (isNegative) {
        oldNumber = -parseInt(oldNumber);
      }

      for (let i = stringLength; i >= 0; i--) {
        if (counter === 2 && i != 0) {
          newNumber = `,${oldNumber[i]}${newNumber}`;
          counter = 0;
        } else {
          newNumber = `${oldNumber[i]}${newNumber}`;
          counter++;
        }
      }
      newNumber = `${isNegative}${newNumber}.${decimalPoint}`;
      return (newNumber);
    },

    showFeeDiv() {
      let show = false;

      if (this.paymentRequestDetail.selectedPaymentPlan && this.paymentRequestDetail.transactions.length > 0) {
        show = true;
      } else if (this.selectedPlan && this.selectedPlan.currentPlan.productType && !this.paymentRequestDetail.payNowUsedCard) {
        show = true;
      }

      return show;
    },
    showCurrentBalance() {
      let show = false;

      if (["Completed"].includes(this.PRDpaymentStatus)) {
        show = true;
      } else if (this.selectedPlan && this.selectedPlan.currentPlan.productType && !this.paymentRequestDetail.payNowUsedCard) {
        show = true;
      }

      return show;
    },

    updatePageData() {
      this.PRDpaymentStatus = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "paymentStatus", "na");
      this.PRDrequestType = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "requestType", "na");
      this.PRDrequestOptions = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "requestOptions", []);
      this.PRDmsf = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "payNowUsedCard.msf", 0);
      this.PRDtransactionAmount = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "transactions[0].amount", 0);
      this.PRDtransactionSurcharge = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "transactions[0].surcharge", 0);
      this.paymentDeclineReason = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "paymentRequestDeclineReason", []);
    },

    approvePR() {
      this.$vs.loading();
      this.approvePaymentRequest({id: this.paymentRequestDetail._id}).then((res) => {
        this.$vs.loading.close();
        this.$emit("pr-approvedOrDeclined");
        this.showMessage("Success", "Payment request has been approved successfully.", "success", "icon-check-circle");
      }).catch((ex) => {
        this.$vs.loading.close();
        let errorMessage = "Error approving payment request.";

        if (ex.data.message.message) {
          errorMessage = ex.data.message.message;
        } else if (ex.data.message) {
          errorMessage = ex.data.message;
        }

        this.showMessage("Error", errorMessage, "danger", "icon-check-circle");
      });
    },

    declinePR() {
      if (!this.declineReason || !this.declineReason.length) {
        this.errors.add({
          "field": "declineReason",
          "msg": "The Decline reason field is required"
        })
        return false;
      }

      const prDeclineReason = this.declineReason.map((item) => {
        return item._id;
      });
      const payload = {
        id: this.paymentRequestDetail._id,
        data: {
          paymentRequestDeclineReason: prDeclineReason
        }
      };

      this.$vs.loading();

      this.declinePaymentRequest(payload).then((res) => {
        this.$vs.loading.close();
        this.$emit("pr-approvedOrDeclined");
        this.showMessage("Success", "Payment request has been declined successfully.", "success", "icon-check-circle");
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-check-circle");
      })

      this.hideDeclinePopUp();
    },

    updateStatus() {
      this.statusButtonDisable = true;
      this.updatePaymentRequest();
      this.statusPopUp = false;
      this.statusButtonDisable = false;
    },

    showErrorMessage(title = "Error", message = "Something went wrong", time = false) {
      this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-danger",
          iconChildren: "highlight_off",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    showDeclinePopUp() {
      this.declinePopUp = true;
    },

    hideDeclinePopUp() {
      this.declinePopUp = false;
      this.declineReason = [];
    },

    selectAllReasons() {
      this.allReasonSelected = !this.allReasonSelected;
      this.declineReason = this.allReasonSelected ? [...this.paymentRequestDeclineReason] : [];
    },

    checkSelected() {
      this.allReasonSelected = (this.declineReason.length == this.paymentRequestDeclineReason.length);
    },

    async getPrDeclineSetting() {
      await this.fetchSetting("paymentRequestDeclineReason").then((res) => {
        this.paymentRequestDeclineDefaultReason = res.data.data;
        this.paymentRequestDeclineReason = res.data.data.filter(item => item.active);
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching setting",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },

    getDeclineText(declineId) {
      let text = "";
      const data = this.paymentRequestDeclineDefaultReason.find((item) => item._id == declineId);

      if (data) {
        text = data.reason;
      }

      return text;
    },

    handleDeclinePopup(val) {
      let parent = document.getElementById("decline-popup");
      let element = parent.getElementsByClassName("vs-popup--content");

      if (val == "open") {
        element[0].classList.add("focused");
      } else {
        element[0].classList.remove("focused");
      }
    },

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup", "decline-popup");
      } else {
        document.body.classList.remove("custom-popup", "decline-popup");
      }
    },
  },

  watch: {
    "register.paymentDate"(newVal, oldVal) {
      if (newVal) {
        const index = this.errors.items.findIndex(object => { return object.field === "paymentDate" });
        this.errors.items.splice(index, 1);
      }
    },

    paymentRequestDetail: [{
      handler: "updatePageData"
    }],

    declinePopUp() {
      this.addPopUpClass(this.declinePopUp);
    },

    statusPopUp() {
      this.addPopUpClass(this.statusPopUp);
    },
  },

  created() {
    VeeValidate.Validator.extend("is_time", {
      getMessage: (field) => `The format must be HH:MM`,
      validate: (value) =>
        new Promise((resolve) => {
          let regex = new RegExp("([0-1][0-9]|2[0-3]):([0-5][0-9])");
          resolve({
            valid: value && regex.test(value),
          });
        }),
    });
    VeeValidate.Validator.extend("requiredAmount", {
      getMessage: (field) => "Amount field is required",
      validate: (value) => !!value,
    });

    this.user = JSON.parse(localStorage.getItem("user"));
    this.getPrDeclineSetting();
  },

  computed: {
    rules() {
      return "requiredAmount|min_value:0.01";
    },

    balanceAfterTransfer() {
      let balance = ((this.paymentRequestDetail.balances && this.paymentRequestDetail.balances.balance ? this.paymentRequestDetail.balances.balance: 0) - this.register.amount);

      if (balance < 0) {
        this.balanceAfterTransferBool = false;
        balance = -balance;
        balance = `-$ ${this.commaSeparator(balance)}`;
      } else {
        this.balanceAfterTransferBool = true;
        balance = `$ ${this.commaSeparator(balance)}`;
      }

      return balance;
    },

    selectedPlan() {
      let plan = null;

      if (this.paymentRequestDetail.requestOptions.includes("pay-later") && ["Active", "Extended", "Completed", "Submitted"].includes(this.paymentRequestDetail.paymentStatus)) {
        plan = this.paymentRequestDetail.selectedPaymentPlan ? this.paymentRequestDetail.selectedPaymentPlan : this.paymentRequestDetail.payLaterPlan[0];
      }

      return plan;
    },

    selectedPlanCancelled() {
      let plan = null;

      if (this.paymentRequestDetail.requestOptions.includes("pay-later") && ["Cancelled"].includes(this.paymentRequestDetail.paymentStatus)) {
        plan = this.paymentRequestDetail.selectedPaymentPlan ? this.paymentRequestDetail.selectedPaymentPlan : this.paymentRequestDetail.payLaterPlan[0];
      }

      return plan;
    },

    isOneOff() {
      return (this.PRDrequestType === "one-off");
    },

    isRecurring() {
      return (this.PRDrequestType === "recurring");
    },

    hasPaylater() {
      return (this.PRDrequestOptions.includes("pay-later"));
    },

    hasPayNow() {
      return (this.PRDrequestOptions.includes("pay-now"));
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

